import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import PowerIcon from "@mui/icons-material/Power";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import "./Dashboard.css";
import Logo from "./logo.png";
import GiftIcon from "./gift-icon.png";
import Navbar from "./navbar";
import PowerGenerationChart from "./PowerGenerationChart";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sessionValid, setSessionValid] = useState(true);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(location.state?.phoneNumber || "");
  const [deviceSn, setDeviceSn] = useState(location.state?.deviceSn || "");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [graphDate, setGraphDate] = useState(dayjs());
  const [viewMode, setViewMode] = useState('day');
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedYear, setSelectedYear] = useState(dayjs());
  const [dashboardData, setDashboardData] = useState({
    currentPvPower: "",
    productionToday: "",
    productionThisMonth: "",
    lifetimeProduction: "",
    lastUpdated: "",
    plantType: "",
    onGridDate: "",
    totalInstalledCapacity: "",
    address: "",
    deviceStatus: "",
    deviceName: "",
    serialNumber: "",
    deviceType: "",
    ratedPower: "",
    communicationMode: "",
    pvGeneration: "",
    selfConsumption: "",
    selfConsumptionRatio: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [referralData, setReferralData] = useState({
    referralLink: "",
    referralCode: "",
  });
  const [userName, setUserName] = useState("");
  const [devices, setDevices] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [referralCode, setReferralCode] = useState("");
  const [powerData, setPowerData] = useState([]);
  const [isDeviceManuallyChanged, setIsDeviceManuallyChanged] = useState(false);

  const fetchDashboardData = useCallback(async () => {
    if (!deviceSn) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/dashboard`,
        { device_sn: deviceSn }
      );
      const data = response.data;

      if (response.status === 401) {
        setError(data.error);
        setLoading(false);
        return;
      }

      setDashboardData({
        currentPvPower: data.Pac,
        productionToday: data.Eday,
        productionThisMonth: data.productionThisMonth,
        lifetimeProduction: data.Etotal,
        lastUpdated: data.CreationDate,
        plantType: data.ModelType,
        onGridDate: "N/A",
        totalInstalledCapacity: "N/A",
        address: "N/A",
        deviceStatus: data.WorkStatus,
        deviceName: "N/A",
        serialNumber: data.INV_SN,
        deviceType: data.ModelType,
        ratedPower: "N/A",
        communicationMode: "N/A",
        pvGeneration: data.pvGeneration,
        selfConsumption: data.selfConsumption,
        selfConsumptionRatio: data.selfConsumptionRatio,
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error.message);
      setError("Error fetching dashboard data. Please try again later.");
      setLoading(false);
    }
  }, [deviceSn]);

  const fetchGraphData = useCallback(async () => {
    if (!deviceSn) return;

    try {
      let response;
      if (viewMode === 'day') {
        const formattedDate = graphDate.format('YYYYMMDD');
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/dashboard`,
          { device_sn: deviceSn, date: formattedDate, view_mode: 'day' }
        );
      } else if (viewMode === 'month') {
        const month = selectedMonth.format('YYYYMM');
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/dashboard`,
          { device_sn: deviceSn, month, view_mode: 'month' }
        );
      } else if (viewMode === 'year') {
        const year = selectedYear.format('YYYY');
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/dashboard`,
          { device_sn: deviceSn, year, view_mode: 'year' }
        );
      }
      
      const data = response.data;

      if (response.status === 401) {
        setError(data.error);
        return;
      }

      if (viewMode === 'day') {
        setPowerData(data.productionDay || []); // Ensure productionDay is an array
      } else if (viewMode === 'month') {
        setPowerData(data.productionMonth || []); // Ensure productionMonth is an array
      } else if (viewMode === 'year') {
        setPowerData(data.productionYear || []); // Ensure productionYear is an array
      }

      
    } catch (error) {
      console.error("Error fetching graph data:", error.message);
      setError("Error fetching graph data. Please try again later.");
    }
  }, [deviceSn, graphDate, viewMode, selectedMonth, selectedYear]);

  useEffect(() => {
    const sessionToken = localStorage.getItem("sessionToken");
    if (!sessionToken) {
      setSessionValid(false);
      return;
    }

    const { expiresAt, phoneNumber, deviceSn } = JSON.parse(sessionToken);
    if (new Date().getTime() > expiresAt) {
      localStorage.removeItem("sessionToken");
      setSessionValid(false);
      setSessionExpired(true);
      return;
    }

    setPhoneNumber(phoneNumber);
    if (!isDeviceManuallyChanged) {
      setDeviceSn(deviceSn);
    }
    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 15000);
    return () => clearInterval(interval);
  }, [fetchDashboardData, isDeviceManuallyChanged]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/check_phone`,
          { phoneNumber }
        );
        const data = response.data;

        setUserName(data.name);
        setDevices(data.devices);

        if (!deviceSn && data.devices.length > 0) {
          setDeviceSn(data.devices[0]);
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
        setError("Error fetching user data. Please try again later.");
      }
    };

    if (phoneNumber) {
      fetchUserData();
    }
  }, [phoneNumber, deviceSn]);

  // Check session expiration every second
  useEffect(() => {
    const interval = setInterval(() => {
      const sessionToken = localStorage.getItem("sessionToken");
      if (sessionToken) {
        const { expiresAt } = JSON.parse(sessionToken);
        if (new Date().getTime() > expiresAt) {
          localStorage.removeItem("sessionToken");
          setSessionValid(false);
          setSessionExpired(true);
        }
      } else {
        setSessionValid(false);
        setSessionExpired(true);
      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  const handleDeviceChange = (device) => {
    setDeviceSn(device);
    setIsDeviceManuallyChanged(true);
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    localStorage.removeItem("sessionToken");
    window.location.href = "/";
  };

  const handleShareClick = () => {
    const randomNumber = Math.floor(Math.random() * 90) + 10; // Generates a number between 10 and 99
    const code = `${deviceSn}${randomNumber}`;
    setReferralCode(code);
    setSharePopupOpen(true);
  };

  const handleShareClose = () => {
    setSharePopupOpen(false);
  };

  const shareReferral = () => {
    const message = encodeURIComponent(
      `Hi! Please use my referral code from Atria Renewable to get up to 15% off on your solar installation! Code: ${referralCode}`
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleMissingValue = (value) => {
    return value !== "" && value !== null && value !== undefined ? value : "--";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date) => {
    setGraphDate(dayjs(date)); // Ensure the date is converted to dayjs
  };

  const handleViewModeChange = (event) => {
    setViewMode(event.target.value);
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(dayjs(date)); // Ensure the date is converted to dayjs
  };

  const handleYearChange = (date) => {
    setSelectedYear(dayjs(date)); // Ensure the date is converted to dayjs
  };

  if (!sessionValid) {
    return (
      <Container className="error-container">
        <Paper className="error-message" elevation={3}>
          <Typography variant="h5" color="error" gutterBottom>
            "Your session has expired. Please login again."
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
            style={{ marginTop: "20px" }}
          >
            Go to Login
          </Button>
        </Paper>
      </Container>
    );
  }

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Container className="error-container">
        <Paper className="error-message" elevation={3}>
          <Typography variant="h5" color="error" gutterBottom>
            {error}
          </Typography>
          <Typography variant="body1">
            {error === "Your session has timed out. Please log in again."
              ? "Your session has expired. Please log in again to continue."
              : "Please verify your device before accessing the dashboard."}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => (window.location.href = "/")}
            style={{ marginTop: "20px" }}
          >
            Go to Login
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container className="container">
      <Navbar
        devices={devices}
        dashboardData={dashboardData}
        userName={userName}
        handleShareClick={handleShareClick}
        handleDeviceChange={handleDeviceChange}
        handleSignOut={handleSignOut}
      />
      <Grid container spacing={3} className="metrics-container">
        {[
          {
            label: "Current PV Power",
            value: handleMissingValue(dashboardData.currentPvPower) + " kW",
            icon: <PowerIcon className="metric-icon" />,
          },
          {
            label: "Production Today",
            value: handleMissingValue(dashboardData.productionToday) + " kWh",
            icon: <WbSunnyIcon className="metric-icon" />,
          },
          {
            label: "Production This Month",
            value:
              handleMissingValue(dashboardData.productionThisMonth) + " kWh",
            icon: <WbSunnyIcon className="metric-icon" />,
          },
          {
            label: "Lifetime Production",
            value:
              handleMissingValue(dashboardData.lifetimeProduction) + " kWh",
            icon: <WbSunnyIcon className="metric-icon" />,
          },
        ].map((metric, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Paper className="metric-box">
              {metric.icon}
              <div className="metric-content">
                <Typography variant="h6">{metric.label}</Typography>
                <Typography variant="subtitle1">{metric.value}</Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel id="view-mode-label">View Mode</InputLabel>
        <Select
          labelId="view-mode-label"
          value={viewMode}
          onChange={handleViewModeChange}
          label="View Mode"
        >
          <MenuItem value="day">Day</MenuItem>
          <MenuItem value="month">Month</MenuItem>
          <MenuItem value="year">Year</MenuItem>
        </Select>
      </FormControl>
      {viewMode === 'day' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            value={graphDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
      {viewMode === 'month' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['month', 'year']}
            label="Select Month"
            value={selectedMonth}
            onChange={handleMonthChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
      {viewMode === 'year' && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['year']}
            label="Select Year"
            value={selectedYear}
            onChange={handleYearChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
      <Box mt={4} mb={4}>
        <PowerGenerationChart
          powerData={Array.isArray(powerData) ? powerData : []}
          viewMode={viewMode}
        />
      </Box>
      <Paper elevation={3}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ textAlign: "center", padding: "10px 0" }}
        >
          Device Details
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Last Updated
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.lastUpdated)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Plant Type
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.plantType)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  On Grid Date
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.onGridDate)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Total Installed Capacity
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.totalInstalledCapacity)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Address
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.address)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Device Status
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.deviceStatus)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Device Name
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.deviceName)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Serial Number
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.serialNumber)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Device Type
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.deviceType)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Rated Power
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.ratedPower)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Communication Mode
                </TableCell>
                <TableCell>{handleMissingValue(dashboardData.communicationMode)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog open={sharePopupOpen} onClose={handleShareClose}>
        <DialogTitle>
          <div style={{ textAlign: "center" }}>
            <img src={GiftIcon} alt="Gift Icon" style={{ width: "100px", height: "100px" }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6" style={{ margin: "20px 0" }}>
              Share and Earn!
            </Typography>
            <Typography variant="body1" style={{ margin: "10px 0" }}>
              Share your referral code and earn up to RS.5000 off Now !
            </Typography>
            <Typography variant="h6" style={{ margin: "10px 0", fontWeight: "bold" }}>
              Referral Code: {referralCode}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={shareReferral}
              style={{ margin: "20px 0", backgroundColor: "#007bff" }}
            >
              Share via WhatsApp
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <a
        href={`https://wa.me/+918904967001?text=${encodeURIComponent(` ${referralCode}`)}`}
        className="whatsapp-float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="flat-whatsapp-icon.png" alt="WhatsApp" className="whatsapp-icon" />
      </a>
    </Container>
  );
};

export default Dashboard;
