import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

const aggregateDataTo15Minutes = (data) => {
  const aggregatedData = [];
  let currentSum = 0;
  let currentCount = 0;
  let currentTime = null;

  data.forEach((item, index) => {
    if (index % 3 === 0) {
      if (currentCount > 0) {
        aggregatedData.push({
          dateTime: currentTime,
          pvPower: currentSum / currentCount,
        });
      }
      currentSum = 0;
      currentCount = 0;
      currentTime = item.dateTime;
    }
    currentSum += item.pvPower;
    currentCount += 1;
  });

  if (currentCount > 0) {
    aggregatedData.push({
      dateTime: currentTime,
      pvPower: currentSum / currentCount,
    });
  }

  return aggregatedData;
};

const aggregateDataToDaily = (data) => {
  const aggregatedData = data.map((item) => ({
    dateTime: item.dateTime,
    pvPower: item.pvGeneration,
  }));
  return aggregatedData;
};

const aggregateDataToMonthly = (data) => {
  const aggregatedData = data.map((item) => ({
    dateTime: item.dateTime,
    pvPower: item.pvGeneration,
  }));
  return aggregatedData;
};

const calculateStepSize = (startDate, endDate) => {
  const diff = endDate - startDate;
  const dayInMilliseconds = 24 * 60 * 60 * 1000;

  if (diff > 30 * dayInMilliseconds) {
    return { timeUnit: 'month', stepSize: 1 };
  } else if (diff > dayInMilliseconds) {
    return { timeUnit: 'day', stepSize: 1 };
  } else {
    return { timeUnit: 'minute', stepSize: 15 };
  }
};

const PowerGenerationChart = ({ powerData, viewMode }) => {
  let aggregatedPowerData = [];
  let timeUnit = 'minute';
  let stepSize = 15;

  if (viewMode === 'day') {
    aggregatedPowerData = aggregateDataTo15Minutes(powerData);
  } else if (viewMode === 'month') {
    aggregatedPowerData = aggregateDataToDaily(powerData);
  } else if (viewMode === 'year') {
    aggregatedPowerData = aggregateDataToMonthly(powerData);
  }

  if (aggregatedPowerData.length > 0) {
    const startDate = new Date(aggregatedPowerData[0].dateTime).getTime();
    const endDate = new Date(aggregatedPowerData[aggregatedPowerData.length - 1].dateTime).getTime();
    const stepSizeConfig = calculateStepSize(startDate, endDate);

    timeUnit = stepSizeConfig.timeUnit;
    stepSize = stepSizeConfig.stepSize;
  }

  const data = {
    labels: aggregatedPowerData.map((item) => item.dateTime),
    datasets: [
      {
        label: 'Power Generation',
        data: aggregatedPowerData.map((item) => item.pvPower),
        fill: true,
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 2,
        pointRadius: 2,
        pointBackgroundColor: 'rgba(255, 159, 64, 1)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
        text: 'Power Generation Over Time',
        font: {
          size: 20,
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeUnit,
          displayFormats: {
            minute: 'HH:mm',
            day: 'MM-dd',
            month: 'MMM',
          },
          stepSize: stepSize,
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
          maxTicksLimit: viewMode === 'year' ? 12 : viewMode === 'month' ? 31 : undefined,
        },
        title: {
          display: true,
          text: viewMode === 'year' ? 'Month' : viewMode === 'month' ? 'Day' : 'Time (HH:mm)',
        },
        grid: {
          display: true,
          color: 'rgba(200, 200, 200, 0.2)',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Power Generation (kW)',
        },
        grid: {
          display: true,
          color: 'rgba(200, 200, 200, 0.2)',
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  return (
    <div style={{ position: 'relative', height: '400px', width: '100%' }}>
      <Line data={data} options={options} />
      {powerData.length === 0 && (
        <p>No data available for the selected date.</p>
      )}
    </div>
  );
};

export default PowerGenerationChart;
