import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Logo from "./Logo - Final 1.png";

export default function Navbar({
  devices,
  userName,
  handleDeviceChange,
  handleShareClick,
  handleSignOut,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#F44336" }}>
      <Toolbar>
        <img
          src={Logo}
          alt="Logo"
          style={{ height: "40px", marginRight: "16px" }}
        />
        <Typography
          variant="h6"
          onClick={handleMenu}
          style={{ cursor: "pointer", marginLeft: "16px" }}
        >
          {userName} <ArrowDropDownIcon />
        </Typography>
        <Box sx={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "white",
              color: "#D32F2F",
              "&:hover": {
                bgcolor: "#D32F2F",
                color: "white",
              },
              marginLeft: isMobile ? 0.5 : 1,
              padding: isMobile ? "6px 8px" : "8px 16px",
              fontSize: isMobile ? "0.75rem" : "0.875rem",
            }}
            onClick={handleShareClick}
          >
            Refer a Friend!
          </Button>
          <Button
            variant="contained"
            sx={{
              bgcolor: "white",
              color: "#D32F2F",
              "&:hover": {
                bgcolor: "#D32F2F",
                color: "white",
              },
              marginLeft: isMobile ? 0.5 : 1,
              padding: isMobile ? "6px 8px" : "8px 16px",
              fontSize: isMobile ? "0.75rem" : "0.875rem",
            }}
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {devices.map((device, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleDeviceChange(device);
                  handleClose();
                }}
              >
                {device}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
